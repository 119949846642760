body {
  margin: 0;
  padding: 0;
  font-family: 'League Spartan', sans-serif;
  background-color: #000e32;
  color: #d6dadf;
}

a {
  color: #1d9ae3;
}

.st-inline-share-buttons {
  z-index: 1 !important;
}

.modal-body .commision_table {
  border: 0;
}

.close {
  color: #d1eefe !important;
  opacity: 1 !important;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #198eec;
}

.main_navbar {
  background-color: transparent !important;
  z-index: 10;
  transition: 0.2s all;
}

.main_navbar.fixed_navbar {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #000e32 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 999;
}

.navbar_right {
  display: flex;
  align-items: center;
}

.logout_btn {
  background: #021039;
  border: 2px solid #0b1c47;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.logout_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}

.logout_btn img {
  margin-right: 10px;
}

.page_header {
  position: relative;
  padding: 100px 0;
  background: url("../src/assets/images/banner_bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 925px;
  margin-top: -115px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.page_header h1 {
  font-size: 60px;
  font-weight: 700;
  color: #feffff;
}

.page_header h5 {
  font-size: 30px;
  font-weight: 400;
  color: #d6dadf;
  max-width: 50%;
  margin: 30px auto 40px;
}

.primary_btn {
  background: rgb(92, 209, 131);
  background: linear-gradient(100deg, rgba(92, 209, 131, 1) 0%, rgba(68, 180, 106, 1) 100%);
  border: 1px solid #6dcd88;
  border-radius: 20px;
  padding: 12px 50px;
  font-size: 30px;
  font-weight: 600;
  color: #feffff;
}

.primary_btn a {
  color: #fff;
  text-decoration: none;
}

section {
  padding: 80px 0;
}

.main_title_small {
  font-size: 24px;
  font-weight: 500;
  color: #51b56d;
}

.main_title {
  font-size: 40px;
  font-weight: 700;
  color: #feffff;
}

.inner_para {
  font-size: 20px;
  color: #d6dadf;
}

.primary_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  color: #fff;
  background: #000e32;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 50px;
  transition: 0.2s all;
}

.primary_box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(to right, #0b1c47, #0b1c47);
  transition: 0.2s all;
}

.partners_slider .slick-current .primary_box::before {
  background: rgb(193, 243, 158);
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
}

.community_section .animated {
  height: 100%;
}

.community_box {
  cursor: default;
  height: 100%;
}

.community_box h4 {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
  color: #feffff;
}

.community_box>div {
  margin-bottom: 20px;
}

.community_box p {
  margin-bottom: 0;
}

.community_section [class*=col-],
.partners_section [class*=col-] {
  margin-bottom: 30px;
}

.counter_section {
  position: relative;
  z-index: 10;
}

.counter_section::before {
  content: "";
  background: url("../src/assets/images/counter_bg.png") no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  height: 506px;
  position: absolute;
  bottom: -61px;
  z-index: -1;
}

.counter_section p {
  font-size: 20px;
  color: #e8d7f8;
  text-align: center;
  margin-bottom: 80px;
}

.primary_box.counter_box {
  padding: 30px 50px;
  min-height: 235px;
}

.primary_box.counter_box::before {
  background: rgb(193, 243, 158);
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
}

.counter_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.counter_panel h3 {
  font-size: 56px;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
  margin: 0;
}

.counter_panel h6 {
  font-size: 18px;
  font-weight: 400;
  color: #d6dadf;
  text-transform: uppercase;
  margin: 0;
}

.counter_panel div {
  position: relative;
  z-index: 1;
}

.counter_panel div::before {
  z-index: -1;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.counter_panel div:nth-child(1)::before {
  content: "";
  background: url("../src/assets/images/count_icon_01.png") no-repeat;
  width: 128px;
  height: 112px;
}

.counter_panel div:nth-child(2)::before {
  content: "";
  background: url("../src/assets/images/count_icon_02.png") no-repeat;
  width: 128px;
  height: 128px;
}

.counter_panel div:nth-child(3)::before {
  content: "";
  background: url("../src/assets/images/count_icon_03.png") no-repeat;
  width: 130px;
  height: 130px;
}

.counter_panel div:nth-child(4)::before {
  content: "";
  background: url("../src/assets/images/count_icon_04.png") no-repeat;
  width: 135px;
  height: 132px;
}

.primary_box.partner_box {
  flex-direction: row;
  padding: 30px 20px;
  align-items: center;
  cursor: default;
}

.partner_box_left {
  width: 40%;
  text-align: center;
}

.partner_box_right {
  width: 60%;
  position: relative;
}

.partner_box_right::before {
  content: "";
  background-color: #031542;
  width: 2px;
  height: 190px;
  position: absolute;
  top: 6px;
  left: -25px;
}

.partner_box_left h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 15px;
}

.partner_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.partner_box_right p {
  font-size: 18px;
  color: #e8d7f8;
  margin: 0;
}

.partner_box_right h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}

.partner_box_right div {
  margin-bottom: 15px;
}

.partners_slider .slick-center .partners_panel {
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  -webkit-transform: scale(1.08);
  transform: scale(1);
}

.partners_slider .partners_panel {
  transition: all 300ms ease;
  transform: scale(0.8);
  position: relative;
}

.partners_slider .partners_panel::before {
  content: "";
  background-color: rgb(0 0 0 / 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 20px;
  z-index: 1;
}

.partners_slider .slick-center .partners_panel::before {
  background-color: transparent;
}

.partners_slider .slick-initialized .slick-slide.slick-active.slick-center.slick-current {
  z-index: 10;
}

.partners_slider .slick-initialized .slick-slide {
  z-index: 1;
  position: relative;
}

.partners_slider .slick-prev:before,
.partners_slider .slick-next:before {
  display: none;
}

.partners_slider .slick-prev {
  left: 320px;
  background-image: url("../src/assets/images/prev_arrow.png");
}

.partners_slider .slick-prev:focus {
  background-image: url("../src/assets/images/prev_arrow.png");
}

.partners_slider .slick-prev:hover {
  background-image: url("../src/assets/images/prev_arrow_hover.png");
}

.partners_slider .slick-next {
  right: 315px;
  background-image: url("../src/assets/images/next_arrow.png");
}

.partners_slider .slick-next:focus {
  background-image: url("../src/assets/images/next_arrow.png");
}

.partners_slider .slick-next:hover {
  background-image: url("../src/assets/images/next_arrow_hover.png");
}

.partners_slider .slick-next,
.partners_slider .slick-prev {
  width: 100px;
  height: 100px;
  transition: .2ms;
  z-index: 5;
  top: 160px;
}

.partners_slider .itemCard {
  transition: all 300ms ease;
  transform: scale(0.8);
  position: relative;
}

.partners_slider .slick-center .itemCard {
  transform: scale(1);
}

.partners_slider .slick-center .itemCard::before {
  display: none;
}

.partners_slider .itemCard::before {
  content: "";
  background-color: rgb(0 0 0 / 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 50px;
  z-index: 1;
}

.commision_table {
  border: 2px solid #0b1c47;
  border-radius: 15px 15px 0 0;
  background: #020f31;
  overflow: hidden;
  margin: 40px 0 0;
}

.commision_table th,
.commision_table td {
  color: #d6dadf;
  font-size: 20px;
  font-weight: 400;
  border: 0 !important;
  vertical-align: middle;
}

.commision_table_div {
  border-radius: 10px;
}

.commision_table th {
  background-color: #198eec;
}

.commision_table tbody {
  border: 3px solid #0b1c47;
}

.commision_table tbody tr:nth-child(even) {
  background-color: #031440;
}

#faq_accodian {
  margin-top: 50px;
}

#faq_accodian .card-header button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  padding: 0;
}

#faq_accodian .card-header button::after {
  content: "";
  background-image: url("../src/assets/images/plus_icon.png");
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  transition: 0.2s all;
}

#faq_accodian .card-header button:not(.collapsed)::after {
  content: "";
  background-image: url("../src/assets/images/close_icon.png");
}

#faq_accodian .card-header,
#faq_accodian .card-body {
  background-color: transparent;
}

#faq_accodian .card {
  position: relative;
  padding: 15px;
  box-sizing: border-box;
  color: #fff;
  background: #000e32;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
  transition: 0.2s all;
  margin-bottom: 30px;
  overflow: unset;
}

#faq_accodian .card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: -webkit-linear-gradient(left, #0b1c47, #0b1c47);
  background: -o-linear-gradient(left, #0b1c47, #0b1c47);
  background: linear-gradient(to right, #0b1c47, #0b1c47);
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
}

#faq_accodian .card:hover::before {
  background: rgb(193, 243, 158);
  background: -webkit-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: -o-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
}

#faq_accodian .card-body {
  padding-top: 0;
  padding-bottom: 0;
}

#faq_accodian .card-body p {
  color: #d6dadf;
  font-size: 20px;
  cursor: default;
}

.footer {
  background-color: #000921;
  padding: 30px 0 20px;
}

.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_panel_left p,
.token_address p {
  margin-bottom: 0;
  font-size: 16px;
}

.footer_panel_left a,
.token_address a {
  font-size: 20px;
  color: #1c97e5;
  white-space: normal;
  word-break: break-all;
}
.token_address a {
  color: #5db96e !important;
}
.footer_social_links {
  display: flex;
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}

.footer_social_links li {
  margin: 0 0 0 15px;
}

.footer_social_links li a {
  width: 44px;
  height: 44px;
  background: #000b28;
  border: 2px solid #0b1c47;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c97e5;
  font-size: 18px;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_social_links li a:hover {
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  color: #fff;
}

.lan_select button {
  border: 2px solid #0b1c47;
  background: #021039;
  border-radius: 30px;
  color: #feffff;
  font-size: 18px;
  padding: 5px 15px;
}

.lan_select button::after {
  border-top: 5px solid #ffffff;
}

.lan_select button[aria-expanded="true"]::after {
  border-bottom: 5px solid #ffffff;
}

.lan_select ul {
  background: #031440;
  border: 2px solid #0b1c47;
  padding: 0;
}

.lan_select ul li:hover,
.lan_select ul li:focus {
  background-color: #020125;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
}

.primary_inp {
  border: 1px solid #0b1c47;
  background: #021039;
}

.title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.inner_title {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}

.wallet_div {
  display: flex;
  align-items: center;
}

.wallet_div label {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  margin: 0px 15px 0 0;
}

.btn-tertiary {
  color: #434f6f;
  padding: 0;
  width: 175px;
  display: block;
  border: 1px solid #0b1c47;
  font-size: 20px;
}

.btn-tertiary:hover,
.btn-tertiary:focus {
  color: #434f6f;
  border-color: #434f6f;
}

/* input file style */
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file+.js-labelFile {
  padding: 30px;
  cursor: pointer;
}

.input-file+.js-labelFile .icon:before {
  content: "";
}

.input-file+.js-labelFile.has-file .icon:before {
  content: "";
  color: #5AAC7B;
}

.radioFlex {
  display: flex;
}

.optionGroup {
  margin-right: 30px;
}

.optionGroup label {
  margin-left: 7px;
}

.coinImg {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.primary_inp {
  border: 1px solid #0b1c47;
  background: #021039;
  color: #ffffff !important;
  font-size: 18px;
  border-radius: 10px;
}

.primary_inp:focus {
  border: 1px solid #0b1c47;
  background: #021039;
  box-shadow: 0;
  outline: none !important;
}

.inp_grp {
  position: relative;
}

.inp_append {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}

.wallet_div input.primary_inp {
  width: 300px;
}

.primary_box.dash_box {
  border-radius: 15px;
  height: 100%;
  padding: 15px 25px;
  justify-content: start;
}

.primary_box.dash_box::before {
  background: rgb(193, 243, 158);
  background: -webkit-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: -o-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
}

.dash_box_left {
  padding: 10px 0px;
  border-bottom: 1px solid #031542;
}

.dash_box_left:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.dash_box_left:first-child {
  padding-bottom: 20px;
}

.dash_box_left h2 {
  font-size: 24px;
  font-weight: 700;
}

.dash_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.dash_box_left h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}

.dash_box_left h3 span {
  color: #e8d7f8;
  font-size: 20px;
}

.dash_box_left p {
  color: #e8d7f8;
  font-size: 18px;
  margin-bottom: 0;
}

.dash_box_left h4 {
  color: #e8d7f8;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.dashbox_middle_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 10px 0;
}

.dashbox_middle_top h2 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

.dashbox_middle_top h4 {
  font-size: 20px;
  font-weight: 400;
  color: #d6dadf;
}

.dashbox_middle_bottom h2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  border-bottom: 1px solid #031542;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.dashboard_top_wrap [class*=col-] {
  margin-bottom: 30px;
}

.dashbox_middle_bottom ul.footer_social_links li a {
  width: 28px;
  height: 28px;
  font-size: 14px;
}

.dashbox_middle_bottom ul.footer_social_links {
  justify-content: center;
  margin-top: 15px;
}

.dashbox_middle_bottom ul.footer_social_links li {
  margin: 0 10px 0px 0;
}

.dashboard_top_wrap>[class*=col-]:last-child {
  margin-bottom: 0;
}

.search_div {
  width: 50%;
  margin: 50px auto 0;
}

.search_div button {
  background: rgb(92, 209, 131);
  border: none;
  background: linear-gradient(100deg, rgba(92, 209, 131, 1) 0%, rgba(68, 180, 106, 1) 16%);
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 1px;
  right: 0;
  border-radius: 0 10px 10px 0;
  padding: 6px 20px;
}

.plan_pool_head {
  background: #000e32;
  background-clip: padding-box;
  border: 2px solid transparent;
  transition: 0.2s all;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  position: relative;
  margin: 10px auto 40px;
}

.plan_pool_head::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: rgb(193, 243, 158);
  background: -webkit-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: -o-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
}

.plan_pool_head::after {
  content: "";
  background-color: #dddddd;
  width: 1px;
  height: 40px;
  position: absolute;
  bottom: -40px;
}

.plan_pool_head_child {
  background: #000e32;
  border: 2px solid #0b1c47;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin: 29px 5px 0;
}

.plan_pool_head_child_active {
  background: #00237d;
  cursor: pointer;
}

.plan_pool_first_row .plan_pool_head_child {
  width: 100px;
  height: 100px;
  font-size: 36px;
}

.plan_pool_first_row {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.plan_pool_first_row::after {
  content: "";
  background-color: #dddddd;
  width: 50%;
  height: 1px;
  position: absolute;
}

.plan_pool_head_child::before {
  content: "";
  background: #ddd;
  width: 1px;
  height: 29px;
  position: absolute;
  top: -31px;
}

.plan_pool_second_row .plan_pool_head_child {
  width: 85px;
  height: 85px;
  font-size: 30px;
  border-radius: 12px;
}

.plan_pool_second_row>div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}

.plan_pool_second_row,
.plan_pool_third_row,
.plan_pool_fourth_row,
.plan_pool_fifth_row {
  display: flex;
  justify-content: space-around;
}

.plan_pool_first_row .plan_pool_head_child::after,
.plan_pool_second_row .plan_pool_head_child::after,
.plan_pool_third_row .plan_pool_head_child::after,
.plan_pool_fourth_row .plan_pool_head_child::after {
  content: "";
  background-color: #dddddd;
  width: 1px;
  height: 30px;
  position: absolute;
  bottom: -32px;
}

.plan_pool_third_row>div,
.plan_pool_fourth_row>div,
.plan_pool_fifth_row>div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}

.plan_pool_second_row>div::before,
.plan_pool_third_row>div::before,
.plan_pool_third_row>div::before,
.plan_pool_fourth_row>div::before,
.plan_pool_fifth_row>div::before {
  content: "";
  background-color: #dddddd;
  width: 50%;
  height: 1px;
  position: absolute;
}

.plan_pool_third_row .plan_pool_head_child {
  width: 65px;
  height: 65px;
  font-size: 24px;
  border-radius: 10px;
}

.plan_pool_fourth_row .plan_pool_head_child {
  width: 45px;
  height: 45px;
  font-size: 20px;
  border-radius: 8px;
}

.plan_pool_fifth_row .plan_pool_head_child {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  font-size: 12px;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #000e32;
}

.primary_modal .modal-header {
  border-bottom: 1px solid #0b1c47;
  padding: 20px 24px 15px;
}

.primary_modal .modal-footer {
  border-top: 1px solid #0b1c47;
}

.connect_wallet_button {
  border: 2px solid #0b1c47;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.connect_wallet_button:hover {
  border-color: #198eec;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #d1eefe;
  ;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #d1eefe;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
}

.primary_modal a {
  text-decoration: none;
}

.plan_pool_third_row .plan_pool_head_child::after {
  display: none;
}

.custom_tooltip {
  background: #198eec !important;
}

.custom_tooltip p {
  font-size: 18px;
}

.custom_tooltip::after {
  border-top-color: #198eec !important;
}

.plan_wrapper p {
  font-size: 18px;
}

.wallet_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}

.wallet_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}

.wallet_btn img {
  margin-right: 10px;
  height: 35px;
  width: 35px;
}

.level_panel {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.primary_box.level_div {
  border-radius: 15px;
  padding: 30px;
  pointer-events: none;
  user-select: none;
  text-align: center;
  position: relative;
}

.primary_box.level_div::after {
  content: "";
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 15px;
  opacity: 0.4;
}

.primary_box.level_div_active::after {
  display: none;
}

.primary_box.level_div_active::before {
  background: rgb(193, 243, 158);
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
}

.primary_btn {
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 3px;
  margin-top: 15px;
  overflow: auto;
  animation: none;
}

.level_div.primary_box .primary_btn {
  background: #11418d;
  border-color: #11418d;
}

.primary_box.level_div.level_div_active .primary_btn {
  font-size: 18px;
  padding: 3px 10px;
  border-radius: 3px;
  margin-top: 15px;
  overflow: auto;
  animation: ripple 1s linear infinite;
  background: #1979e5;
  border-color: #1979e5;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(100, 194, 255, 0.1), 0 0 0 5px rgba(100, 194, 255, 0.1), 0 0 0 10px rgba(100, 194, 255, 0.1), 0 0 0 15px rgba(100, 194, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 5px rgba(100, 194, 255, 0.1), 0 0 0 15px rgba(100, 194, 255, 0.1), 0 0 0 30px rgba(100, 194, 255, 0);
  }
}

.primary_btn.btnHomeJoin {
  background: #5cd183;
  background: linear-gradient(100deg, #5cd183, #44b46a);
  border: 1px solid #6dcd88;
  border-radius: 20px;
  color: #feffff;
  font-size: 30px;
  font-weight: 600;
  padding: 12px 50px;
}



.buttonGlown {
  position: relative;
  text-align: center;
  padding: 3px 10px;
  font-size: 18px;
  font-weight: 600;
  border: 2px solid #44b46a;
  transition: 1.5s;
  background: transparent;
  color: #44b46a;
  border-radius: 5px;
  margin-bottom: 0px;
  margin-top: 15px;
  box-shadow: 0 1px 15px 0 #44b46a inset, 0 1px 9px 0 #44b46a,
    0px 1px 9px 0 #44b46a inset, 0 1px 9px 0 #44b46a;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 1px 10px 0 rgba(255, 215, 0, .35) inset, 0 1px 9px 0 rgba(255, 215, 0, .35),
      0px 1px 9px 0 rgba(255, 215, 0, .35) inset, 0 1px 9px 0 rgba(255, 215, 0, .35);
  }

  to {
    box-shadow: 0 1px 10px 0 #FFD700 inset, 0 1px 9px 0 #FFD700,
      0px 1px 9px 0 #FFD700 inset, 0 1px 9px 0 #FFD700;
  }
}

.claimGlown {
  position: relative;
    padding: 10px 25px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    border: 2px solid #6ad384;
    transition: 1.5s;
    background: #6ad384;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 15px 0 #6ad384 inset, 0 1px 9px 0 #6ad384, 0px 1px 9px 0 #6ad384 inset, 0 1px 9px 0 #6ad384;
    animation: glow 1s ease-in-out infinite alternate;    
}

@keyframes glow {
  from {
    box-shadow: 0 1px 10px 0 rgba(106, 211, 132, 0.51) inset, 0 1px 9px 0 rgb(106, 211, 132, 0.3),
      0px 1px 9px 0 rgba(106, 211, 132, 0.51) inset, 0 1px 9px 0 rgba(106, 211, 132, 0.3);
  }

  to {
    box-shadow: 0 1px 10px 0 #6ad384 inset, 0 1px 9px 0 #6ad384,
      0px 1px 9px 0 #6ad384 inset, 0 1px 9px 0 #6ad384;
  }
}

.primary_box.level_div.level_div_active .primary_btn.activated_btn {
  animation: none;
  background: #6ad284;
  border-color: #6ad284;
  ;
}

.level_div.primary_box.level_div_active {
  cursor: pointer;
  pointer-events: unset;
}

.level_div h4 {
  color: #97c287;
}

.address_btn {
  margin-bottom: 0;
  width: 156px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.dropdown-toggle:after {
  will-change: transform;
  transition: -webkit-transform .15s linear;
  transition: transform .15s linear;
  transition: transform .15s linear,
    -webkit-transform .15s linear;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.dropdown.show .dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.walletDropMain {
  background: #031440;
  border: 2px solid #0b1c47;
  padding: 15px;
  color: #fff;
  min-width: 250px;
  border-radius: 0.25rem;
}

.walletDropMain label {
  color: #46b66c;
}

.walletDropMain>p {
  border-bottom: 1px solid #152a5f;
}

.walletDropMain a {
  font-size: 18px;
  color: #45b66b;
  margin: 0 0 15px;
}

#register_modal .primary_btn,
.auth_wrap .primary_btn {
  padding: 8px 30px;
  font-size: 18px;
}

.footer_social_links li a img {
  width: 18px;
}

.footer_social_links li a:hover img {
  filter: brightness(0) invert(1);
}

.primary_table .rdt_TableHeadRow {
  background: #44b46a;
}

.primary_table .rdt_Table,
.primary_table .rdt_TableRow,
.primary_table .rdt_Pagination,
.primary_table .rdt_Table>div {
  background-color: transparent;
}

.primary_table .rdt_TableCol {
  font-size: 18px;
  font-weight: 400;
  color: rgb(255 255 255);
}

.primary_table .rdt_TableCell {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}

.primary_table .rdt_TableCell a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
}

.primary_table .rdt_TableCell a:hover {
  text-decoration: underline;
}

.primary_table .rdt_TableRow {
  padding: 15px 0;
  border-bottom-color: #151F49 !important;
}

.primary_table .rdt_Pagination {
  color: rgba(255, 255, 255, 0.6);
  border-top-color: #151F49 !important;
  font-size: 14px;
}

.primary_table .rdt_Pagination button {
  fill: rgba(255, 255, 255, 0.6);
}

.primary_table .rdt_Pagination button:disabled {
  fill: rgb(128 128 128 / 50%);
}

.primary_table {
  border: 1px solid #151F49
}

.auth_wrap {
  position: relative;
  background: url("../src/assets/images/banner_bg.png") no-repeat;
  background-size: cover;
  background-position: center;
}

.auth_wrap .inner_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth_wrap .title_flex {
  margin-bottom: 0;
}

.auth_wrap .dashbox_middle_top {
  display: block;
  text-align: left;
}

.auth_wrap .dashbox_middle_top p {
  margin-bottom: 5px;
}

.rdt_Table>div {
  color: #fff !important;
}

div#google_translate_element {
  height: 45px;
}

select.goog-te-combo {
  height: 35px;
  background: #021039;
  border: 2px solid #0b1c47;
  color: #fff;
  font-size: 12px;
  transition: 0.2s all;
  padding: 4px 4px;
  border-radius: 30px;
  position: absolute;
  right: 15px;
  outline: none;
  z-index: 10;
  top: 5px;
}

.affiliate_link {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 40px;
}

.st-inline-share-buttons {
  display: flex !important;
  flex-direction: row;
}

.st-btn {
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.st-btn>img {
  height: 20px !important;
  width: 20px !important;
  top: 0 !important;
}

.navbar_right .primary_btn {
  padding: 5px 20px;
  font-size: 18px;
  border-radius: 10px;
}

.submit_btn {
  align-items: center;
  margin: auto;
  background: rgb(92, 209, 131);
  background: linear-gradient(100deg, rgba(92, 209, 131, 1) 0%, rgba(68, 180, 106, 1) 100%);
  border: 1px solid #6dcd88;
  border-radius: 20px;
  margin-right: 10px;
  padding: 0px 44px;
  font-size: 30px;
  font-weight: 600;
  color: #feffff;
  width: 170px;
}

.submit_btn2 {
  align-items: center;
  margin: auto;
  background: rgb(240 14 14);
  border: 1px solid #ef0101;
  border-radius: 20px;
  margin-top: 10px;
  padding: 0px 44px;
  font-size: 30px;
  font-weight: 600;
  color: #feffff;
  width: 186px;
}

.onclick {
  text-align: center;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  fill: #2e80c0 !important;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 99999;
}

.loader_section_::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.25);
  z-index: 1500;
  overflow: hidden;
}

.loading svg {
  z-index: 1501;
  position: relative;
}

span.input-group-text {
  background: #4bbd71;
  border: 1px solid #4bbd71;
  color: #fff;
}

.input-group input.primary_inp {
  border-radius: 0;
  padding-left: 8px;
}

.input-group .input-group-prepend span.input-group-text {
  width: 40px;
  justify-content: center;
}

.primary_box.dash_box.make_proposal_panel {
  padding: 30px;
  height: auto;
}

.proposal_action_grp_btns button:first-child {
  margin-right: 10px;
}

.proposal_creator_details>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.proposal_creator_details>div label:nth-child(2) {
  color: #4bbd71;
}

.voting_power_overview_div {
  background: #4bbd71;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;
}

.voting_power_overview_detail div,
.voting_power_overview_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.voting_power_overview_detail div label,
.voting_power_overview_div h5 {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
}

.voting_power_overview_div h5 {
  margin: 0;
}

.inner_title_top {
  display: flex;
}

.inner_title_top h1,
.inner_title_top h3 {
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.voting_top_flex {
  position: relative;
}

.nav_primary {
  padding-left: 0;
  border-bottom: 0;
}

.nav-tabs .nav-item {
  margin-bottom: -3px;
}

.nav_primary .nav-item .nav-link {
  color: #acabd1 !important;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500 !important;
  padding: 15px 50px;
  transition: all .2s;
}

.nav_primary .nav-item .nav-link.active {
  background-color: #000e32;
  border-radius: 18px 18px 0 0;
  border: 2px solid #c1f39e !important;
  border-bottom: 0 !important;
  color: #5cd183 !important;
}

.voting_top_flex_btns {
  position: absolute;
  right: 0;
  top: -6px;
}

.voting_top_flex_btns button {
  color: #1979e5;
  text-transform: capitalize;
  border: 1px solid transparent;
  font-size: 20px;
  font-weight: 500;
  padding: 6px 20px;
  margin-left: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.voting_top_flex_btns button.active {
  background: #1979e5;
  border: 1px solid #1979e5;
  color: #fff;
}

.swap_pro_order_history .primary_box.dash_box.make_proposal_panel {
  border-radius: 0 10px 10px 10px;
}

.voting_proposal_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #021544;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid transparent;
  transition: all .2s;
  margin-bottom: 30px;
  position: relative;
  text-decoration: none !important;
}

.voting_proposal_div:hover {
  box-shadow: 0 3px 6px rgb(24 109 211 / 50%);
}

.voting_proposal_div_left {
  width: 85%;
}

.voting_details_head_min {
  min-height: 120px;
}

.voting_proposal_div .progress {
  background: #000e32;
}

.progress {
  background: #061b52;
  border-radius: 2px;
  height: 8px;
}

.agree_text {
  color: #46b66c;
  font-size: 18px;
  text-transform: capitalize;
}

.disagree_text {
  font-size: 18px;
  color: #bbc8e7;
  text-transform: capitalize;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #46b66c;
  transition: width .6s ease;
}

.voting_proposal_div_left h2 {
  color: #fff;
  font-size: 22px;
  margin: 0 0 5px;
}

.voting_status {
  font-size: 18px;
}

.status_open {
  color: #8bff26 !important;
}

.status_closed {
  color: #ff5002 !important;
}

.status_coming_soon {
  color: #fff70c !important;
}

.inner_banner_content p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.got_suggest p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
}

.vote_poll_div {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
}

.vote_poll_div p {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.custom-radios div {
  display: inline-block;
  margin-right: 20px;
  transition: all .2s;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid transparent;
}

.custom-radios input[type=radio] {
  display: none;
}

.custom-radios input[type=radio]+label {
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.custom-radios input[type=radio]+label span {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  background-color: #02174d;
  border: 1px solid #02174d;
  box-shadow: 0 3px 6px 0 rgb(21 0 46 / 47%);
  background-repeat: no-repeat;
  background-position: 50%;
  text-align: center;
  line-height: 28px;
}

.custom-radios input[type=radio]+label span img {
  opacity: 0;
  transition: all .3s ease;
}

.custom-radios div:hover {
  border: 1px solid #48b96e;
  box-shadow: 0 3px 6px rgb(106 99 169 / 65%);
}

.custom-radios input[type=radio]:checked+label span img {
  opacity: 1;
}

.custom-radios input[type=radio]:checked+label span {
  background-color: #48b96e;
  border: 1px solid #48b96e;
}

.custom-radios input[type=radio]:checked+label {
  color: #48b96e;
}

.agree_result_div h4,
.disagree_result_div h4 {
  font-size: 18px;
  text-transform: capitalize;
}

.green_txt {
  color: #8bff26 !important;
}

h2.whitebox-title {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  border-bottom: 1px solid #031542;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.order_book_table {
  margin-bottom: 0;
}

.order_book_table th {
  color: #48b96e;
  font-size: 16px;
  font-weight: 400;
  padding: 3px;
  border: 0 !important;
}

.order_book_table td {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 3px;
  border: 0 !important;
}

.votes_list_table {
  height: 100px;
  overflow: auto;
}

.vote_poll_div {
  height: 230px !important;
}

.whitebox_vote_details {
  min-height: 310px;
}

.vote_details_list_bottom div,
.vote_details_list_top div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main_navbar li {
  list-style: none;
  margin-right: 15px;
}
.main_navbar li a {
  color: #fff;
}
.navbar_right ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.navbar_right button.primary_btn {
  margin-top: 0;
}
button.primary_btn.btnHomeJoin.join_now_btn {
  background: #0a4ac3;
  border: 1px solid #0a4ac3;
}
.coin_info_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}
.coin_info_wrap > div {
  width: 50%;
  margin-bottom: 15px;
}
.coin_info_wrap > div label {
  color: #51b56d;
  font-size: 20px;
  font-weight: 500;
}
.coin_info_wrap > div h4 {
  color: #feffff;
  font-weight: 500;
}
.primary_box.dash_box.exclusive_box_wrap {
  padding: 0;
}
.exclusive_box {
  background: url("./assets/images/exclusive_bg.png");
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.exclusive_box > img {
  margin: -20px 50px -20px;
}
.exclusive_box_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 50px;
  width: 100%;
}
.exclusive_box_content > div span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.exclusive_box_content > div:last-child {
  width: 100%;
}
.exclusive_box_content > div {
  padding: 0 30px;
}
.exclusive_box_content > div span:nth-child(1) {
  display: block;
  color: #b0b5bc;
  font-size: 20px;
  position: relative;
}
.exclusive_box_content > div span:nth-child(1)::before {
  content: "";
  background: url("./assets/images/icon_check.png") no-repeat;
  width: 14px;
  height: 10px;
  position: absolute;
  top: 8px;
  left: -25px;
}



/* Media Queries */
@media screen and (max-width: 1199px) {
  .plan_pool_fifth_row {
    transform: scale(0.85);
  }

  .dashbox_middle_top h2 {
    font-size: 32px;
  }

  .partner_box_right h3,
  .partner_box_left h2,
  .partner_box_left h5 {
    font-size: 20px;
  }

  .partner_box_right::before {
    left: -12px;
  }
}

@media screen and (max-width: 991px) {
  .plan_pool_fourth_row {
    transform: scale(0.80);
  }

  .plan_pool_fifth_row {
    transform: scale(0.65);
  }

  .plan_pool_fifth_row>div {
    margin-top: 6px;
  }

  .plan_pool_head {
    width: 110px;
    height: 110px;
    font-size: 32px;
  }

  .plan_wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .title_flex {
    display: block;
    text-align: center;
  }

  .wallet_div {
    display: block;
  }

  .inner_title {
    margin-bottom: 15px;
  }

  .wallet_div input.primary_inp {
    width: 100%;
  }

  .wallet_div label {
    margin: 0 0 10px 0;
  }

  .footer_panel {
    display: block;
    text-align: center;
  }

  .footer_social_links {
    justify-content: center;
    margin-top: 15px;
  }

  .page_header h1 {
    font-size: 42px;
  }

  .page_header h5 {
    max-width: 80%;
  }

  .counter_panel h3 {
    font-size: 40px;
  }

  .counter_panel div::before {
    background-size: 70% !important;
    top: -20px;
    background-position: center !important;
  }

  .level_panel {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 30px;
  }

  .voting_top_flex_btns {
    position: relative;
    right: unset;
    top: unset;
    margin-bottom: 15px;
  }

  .proposal_panel_right {
    margin-top: 30px;
  }
  .exclusive_box > img {
    display: none;
  }
  .exclusive_box_content {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 767px) {
  .plan_pool_third_row .plan_pool_head_child {
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 5px;
  }

  .plan_pool_fourth_row .plan_pool_head_child {
    width: 30px;
    height: 30px;
    font-size: 14px;
    border-radius: 3px;
  }

  .plan_pool_fifth_row .plan_pool_head_child {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    font-size: 10px;
  }

  .plan_pool_second_row .plan_pool_head_child {
    width: 56px;
    height: 56px;
    font-size: 24px;
    border-radius: 8px;
  }

  .plan_pool_first_row .plan_pool_head_child {
    width: 65px;
    height: 65px;
    font-size: 28px;
    border-radius: 8px;
  }

  .dashbox_middle_top {
    display: block;
  }

  .dashbox_middle_top>div {
    margin-bottom: 30px;
  }

  .dashbox_middle_top>div:last-child {
    margin-bottom: 0;
  }

  .dashbox_middle_top h2 {
    margin-bottom: 0;
  }

  .plan_wrapper {
    overflow-x: unset;
  }

  .search_div {
    width: 100%;
  }

  .footer_panel_left a {
    font-size: 20px;
  }

  .navbar_right {
    display: block;
    margin-top: 15px;
  }

  button.logout_btn {
    margin-bottom: 15px;
  }

  .main_navbar {
    background-color: #000e32 !important;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  }

  .page_header h1 {
    font-size: 36px;
  }

  .page_header h5 {
    max-width: 100%;
    font-size: 24px;
  }

  .primary_btn {
    font-size: 16px;
  }

  .main_title {
    font-size: 36px;
  }

  .counter_panel {
    display: block;
    text-align: center;
  }

  .counter_panel div {
    margin-bottom: 30px;
  }

  .counter_panel div::before {
    background-size: 60% !important;
    top: -30px;
  }

  .partner_box_right::before {
    left: -56px;
  }

  .faq_section .text-right {
    text-align: left !important;
  }

  .partners_section p.inner_para a {
    white-space: normal;
    word-break: break-all;
  }

  div#google_translate_element {
    height: 48px;
  }

  #faq_accodian .card-header button span {
    display: inline-block;
    max-width: 76%;
  }
  .claimGlown {
    margin: 0 auto;
  }
  .main_navbar li {
    margin: 10px 0;
  }
}


@media screen and (max-width: 575px) {
  .plan_pool_fourth_row .plan_pool_head_child {
    width: 32px;
    height: 32px;
  }

  .plan_pool_fifth_row .plan_pool_head_child {
    width: 16px;
    height: 16px;
  }

  .dashbox_middle_bottom h2 {
    font-size: 20px;
  }

  .plan_pool_fourth_row {
    transform: scale(0.65);
  }

  .plan_pool_fifth_row {
    transform: scale(0.52);
  }

  .plan_pool_fifth_row>div {
    margin-top: -9px;
  }

  .plan_pool_fourth_row>div {
    margin-top: 6px;
  }

  .plan_pool_head {
    width: 80px;
    height: 80px;
    font-size: 30px;
  }

  .plan_pool_third_row {
    transform: scale(0.8);
  }

  .plan_pool_second_row {
    transform: scale(0.8);
  }

  .plan_pool_second_row>div {
    margin-top: 24px;
  }

  .plan_pool_third_row>div {
    margin-top: 8px;
  }

  .inner_para {
    font-size: 18px;
  }

  .main_title_small {
    font-size: 20px;
  }

  .main_title {
    font-size: 28px;
  }
}

@media screen and (max-width: 479px) {
  .plan_pool_fourth_row {
    transform: scale(0.515);
  }

  .plan_pool_fifth_row {
    transform: scale(0.42);
  }

  .plan_pool_fifth_row>div {
    margin-top: -18px;
  }

  .navbar-brand img {
    width: 220px;
  }

  .level_panel {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .primary_box.dash_box.make_proposal_panel {
    padding: 15px;
  }

  .choice_inp input.primary_inp {
    width: 70%;
  }
  .coin_info_wrap > div {
    width: 100%;
  }
}